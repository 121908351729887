<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }">
			<v-icon v-if="task.note" class="pr-0" v-on="on">chat_bubble</v-icon>
		</template>
		<pre class="font-italic" v-text="task.note" />
	</v-tooltip>
</template>

<script>
export default {
	name: 'TasksManagerTaskNotesIcon',
	props: {
		task: {
			required: true,
			type: Object
		}
	}
}
</script>
